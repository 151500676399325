import { EsteiraTech } from '../esteiratech';

EsteiraTech.startPage('login',function(){

    console.log("LOADING")
    let loginEmail = localStorage.getItem("loginEmail")
    let loginPassword = localStorage.getItem("loginPassword")
    let loginRemember = localStorage.getItem("loginRemember")
    if( loginEmail ){
        document.getElementById("email").value = loginEmail
    }
    if( loginPassword ){
        document.getElementById("password").value = loginPassword
    }
    if( loginRemember ){
        document.getElementById("remember").checked = Boolean(loginRemember)
    }

})
